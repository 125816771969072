// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import url("https://fonts.google.com/specimen/Poppins?query=poppins");

html,
body {
  margin: 0;
  height: 100%;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #0dcfe9;
  --secondary-color: #5351fb;
  --default-logo: ../assets/default-logo.png;
  --default-favicon: favicon.ico

  --primary-dark-blue: #023e73;
  --primary-bright-blue: #2e6ff2;
  --primary-dark-purple: #590232;
  --primary-vibrant-magenta: #a60a53;

  --primary-black: rgb(0, 0, 0);
  --primary-grey: rgb(178 173 173);
  --primary-white: rgb(255, 255, 255);

  --padding-extra-small: 1px;
  --padding-small: 4px;
  --padding-medium: 7px;
  --padding-large: 11px;
  --padding-extra-large: 15px;
  --padding-xx-large: 20px;

  --font-extra-small: 7px;
  --font-small: 13px;
  --font-medium: 16px;
  --font-large: 21px;
  --font-extra-large: 25px;
  --font-xx-large: 27px;
  --font-xxx-large: 28px;
}

.mat-expansion-indicator::after {
  height: 16px;
  width: 16px;
  color: var(--primary-color);
  border: 3px solid #061e64;
}

@media (min-width: 200px) {
  :root {
    --font-extra-small: 7px;
    --font-small: 13px;
    --font-medium: 13px;
    --font-large: 18px;
    --font-extra-large: 22px;
    --font-xx-large: 25px;
    --font-xxx-large: 28px;

    --padding-extra-small: 5px;
    --padding-small: 9px;
    --padding-medium: 14px;
    --padding-large: 19px;
    --padding-extra-large: 20px;
    --padding-xx-large: 20px;

    --heading-1: 45px;
    --heading-home: 25px;
  }
}

@media (min-width: 400px) {
  :root {
    --font-extra-small: 7px;
    --font-small: 13px;
    --font-medium: 14px;
    --font-large: 19px;
    --font-extra-large: 23px;
    --font-xx-large: 27px;
    --font-xxx-large: 31px;

    --padding-extra-small: 7px;
    --padding-small: 13px;
    --padding-medium: 17px;
    --padding-large: 21px;
    --padding-extra-large: 25px;
    --padding-xx-large: 20px;

    --heading-1: 40px;
    --heading-home: 25px;
  }
}

@media (min-width: 700px) {
  :root {
    --font-extra-small: 8px;
    --font-small: 12px;
    --font-medium: 14px;
    --font-large: 20px;
    --font-extra-large: 24px;
    --font-xx-large: 28px;
    --font-xxx-large: 32px;

    --padding-extra-small: 8px;
    --padding-small: 14px;
    --padding-medium: 18px;
    --padding-large: 22px;
    --padding-extra-large: 26px;
    --padding-xx-large: 33px;

    --heading-1: 56px;
    --heading-home: 28px;
  }
}

@media (min-width: 768px) {
  :root {
    --font-medium: 16px;
  }
}

@media (min-width: 1000px) {
  :root {
    --font-extra-small: 9px;
    --font-small: 13px;
    // --font-medium: 17px;
    --font-medium: 16px;
    --font-large: 21px;
    --font-extra-large: 25px;
    --font-xx-large: 29px;
    --font-xxx-large: 33px;

    --padding-extra-small: 9px;
    --padding-small: 15px;
    --padding-medium: 19px;
    --padding-large: 23px;
    --padding-extra-large: 27px;
    --padding-xx-large: 38px;

    --heading-1: 60px;
    --heading-home: 30px;
  }
}

@media (min-width: 1300px) {
  :root {
    --font-extra-small: 10px;
    --font-small: 14px;
    // --font-medium: 20px;
    --font-medium: 16px;
    --font-large: 22px;
    --font-extra-large: 26px;
    --font-xx-large: 30px;
    --font-xxx-large: 34px;

    --padding-extra-small: 10px;
    --padding-small: 16px;
    --padding-medium: 20px;
    --padding-large: 24px;
    --padding-extra-large: 28px;
    --padding-xx-large: 46px;

    --heading-1: 62px;
    --heading-home: 33px;
  }
}

@media (min-width: 1700px) {
  :root {
    --font-extra-small: 11px;
    --font-small: 15px;
    // --font-medium: 21px;
    --font-medium: 18px;
    --font-large: 23px;
    --font-extra-large: 27px;
    --font-xx-large: 33px;
    --font-xxx-large: 39px;

    --padding-extra-small: 11px;
    --padding-small: 17px;
    --padding-medium: 22px;
    --padding-large: 25px;
    --padding-extra-large: 29px;
    --padding-xx-large: 51px;

    --heading-1: 62px;
    --heading-home: 35px;
  }
}

@media (min-width: 2000px) {
  :root {
    --font-extra-small: 12px;
    --font-small: 16px;
    // --font-medium: 22px;
    --font-medium: 20px;
    --font-large: 24px;
    --font-extra-large: 28px;
    --font-xx-large: 36px;
    --font-xxx-large: 44px;

    --padding-extra-small: 12px;
    --padding-small: 18px;
    --padding-medium: 23px;
    --padding-large: 26px;
    --padding-extra-large: 20px;
    --padding-xx-large: 56px;

    --heading-1: 64px;
    --heading-home: 35px;
  }
}

::-webkit-scrollbar {
  width: 10px; // Remove scrollbar space //
  background: white; // Optional: just make scrollbar invisible //
  padding: 20px;
  height: 20px;
  margin: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8d979b;
  border-radius: 2px;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
.unselectable,
.card {
  -webkit-user-select: none; // Chrome all // Safari all //
  -moz-user-select: none; // Firefox all //
  -ms-user-select: none; // IE 10+ //
  user-select: none; // Likely future //
  cursor: pointer;
}

mat-error {
  color: #f44336 !important;
}

.btn,
.btn:focus {
  border: none !important;
  outline: none !important;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

.fade {
  background-color: rgba(0, 0, 0, 0.5);
}
